/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaHome, FaPhoneAlt, FaEnvelope, FaGlobe } from "react-icons/fa";

import "./global.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="page-container">
        <div className="content-wrap">
          <main>{children}</main>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <h3 className="has-text-left has-text-weight-bold">
                  About us
                </h3>
                <p className="has-text-left mt-3">
                  Taxi Solutions work with your taxi firm to deliver your growth plan. We offer a range of services to suit your budget and requirements. Call our team to see what we can do to support you.
                </p>
              </div>
              <div className="column is-4">
                <h3 className="has-text-left has-text-weight-bold">Contact</h3>
                <ul className="mt-3 has-text-left contact">
                  <li><FaHome /> &nbsp;46-48 Odsal Rd <br /> Bradford BD6 1AQ, UK</li>
                  <li><FaPhoneAlt /> &nbsp;0330 088 1185</li>
                  <li>
                    <a href="mailto:info@taxibooker.app"><FaEnvelope /> &nbsp;info@taxibooker.app</a>
                  </li>
                  <li><FaGlobe /> &nbsp;taxibooker.app</li>
                </ul>
              </div>
              <div className="column is-4">
                <h3 className="has-text-left has-text-weight-bold">Social</h3>
                <ul className="mt-3 social" style={{ display: "flex", justifyContent: "start" }}>
                  <li><a href="https://www.linkedin.com/company/taxibooker/" target="_blank" rel="noreferrer"><FaLinkedin style={{ fontSize: "2rem" }} /></a></li>
                  <li><a href="https://www.facebook.com/taxibooker/" target="_blank" rel="noreferrer"><FaFacebookSquare style={{ fontSize: "2rem" }} /></a></li>
                  <li><a href="https://www.instagram.com/taxibooker/" target="_blank" rel="noreferrer"><FaInstagramSquare style={{ fontSize: "2rem" }} /></a></li>
                  <li><a href="https://twitter.com/TaxiBooker" target="_blank" rel="noreferrer"><FaTwitterSquare style={{ fontSize: "2rem" }} /></a></li>
                </ul>
              </div>
            </div>

            <div className="mt-5">
              Copyright © {new Date().getFullYear()} Taxi Solutions
            </div>
          </div>
        </footer>
      </div>

      <script type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.$crisp=[];window.CRISP_WEBSITE_ID="e180ec9e-99cd-49ec-af4d-77721864207c";(function(){d = document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
        `,
        }}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
